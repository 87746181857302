import React from 'react';

function PizzaTeig() {
    const [amountOfPizzas, setAmountOfPizzas] = React.useState(3);
    const mehl = Math.ceil((amountOfPizzas * 166) / 10) * 10;
    const wasser = Math.ceil((amountOfPizzas * 108) / 10) * 10;
    const hefe = amountOfPizzas / 2;
    const salz = amountOfPizzas / 0.2;

    return <>

        <h1>Pizzateig-Rezept</h1>
        <>
            <label htmlFor='amount-of-pizzas-field'>Anzahl der Pizzen: </label>
            <strong>{amountOfPizzas}</strong><br />
            <input
                required
                type='range'
                min={1}
                max={20}
                id='amount-of-pizzas-field'
                value={amountOfPizzas}
                onChange={(event) => {
                    setAmountOfPizzas(Number(event.target.value));
                }}
            />
        </>
        <>
            {amountOfPizzas === 1 && <h2>Benötigte Zutaten für 1 Pizza</h2>}
            {amountOfPizzas > 1 && <h2>Benötigte Zutaten für {amountOfPizzas} Pizzen</h2>}
            <div>
                <strong>
                    {mehl}
                    g
                </strong>
                {' '}
                Pizzamehl
            </div>
            <div>
                <strong>
                    {wasser}
                    ml
                </strong>
                {' '}
                kaltes Wasser
            </div>
            <div>
                <strong>
                    {hefe}
                    g
                </strong>
                {' '}
                Hefe
            </div>
            <div>
                <strong>
                    {salz}
                    g
                </strong>
                {' '}
                Salz
            </div>
        </>
        <h2>Beschreibung</h2>

        <h3>Tag 1 - Teig kneten</h3>
        <p>
            Damit der Teig den vollen Geschmack entfalten kann, werden 48 Stunden
            benötigt. Das meiste davon ist Ruhezeit, in welcher der Teig ruht.
        </p>
        <p>
            Zu Beginn wird die
            {' '}
            <strong>Hefe</strong>
            {' '}
            mit dem
            {' '}
            <strong>kalten Wasser</strong>
            {' '}
            vermengt. Dazu gerne einen
            Schneebesen oder eine Gabel nehmen. Nach und nach
            {' '}
            <strong>Mehl</strong>
            {' '}
            hinzugeben, am besten in circa 100g-Schritten
            und dabei weiter umrühren, zum Beispiel mit einem Löffel. Wenn das
            Mehl fast komplett aufgebraucht ist, sollte der Teig eine gewisse
            Festigkeit angenommen haben und kann mit der Hand geknetet werden.
        </p>
        <p>
            Der Teig wird nun 20 Minuten lang auf einer harten Oberfläche gut
            durchgeknetet. Zu Beginn kann das
            {' '}
            <strong>Salz</strong>
            {' '}
            hinzugefügt
            werden. Da der Teig noch etwas klebrig ist können die Oberfläche und
            Hände immer wieder mit etwas Mehl bestäubt werden, allerdings nicht
            zuviel, da sonst das Mengenverhältnis nicht mehr passt und der Teig
            zu trocken wird.
        </p>
        <p>
            Am Ende der 20 Minuten eine Kugel formen und den Teig 20 Minuten
            lang abgedeckt ruhen lassen. Ganz zum Schluss den Teig in eine
            Schale geben und diese mit einem feuchten Küchentuch abdecken, die
            Schale für 24 Stunden im Kühlschrank ruhen lassen.
        </p>
        <h3>
            Tag 2 - Teigling
            {amountOfPizzas > 1 && 'e portionieren und'}
            {' '}
            vorbereiten
        </h3>
        <p>
            Den Teig aus der Schale nehmen und nochmals gut durchkneten. Dass
            dabei Luft/Gas wieder entweicht ist okay.
            {' '}
            {amountOfPizzas > 1
                && `Der Teig wird nun in ${amountOfPizzas} circa gleich große Stücke zerkleinert. Jeder
            Teigling sollte um die 280g wiegen. Die Teiglinge zu Kugeln formen
            und wenn gewünscht die Oberfläche unter Spannung bringen.`}
            {' '}
            {amountOfPizzas === 1
                && `Der Teigling sollte um die 280g wiegen. Diesen zur Kugeln formen
            und wenn gewünscht die Oberfläche unter Spannung bringen.`}
        </p>
        <p>
            {amountOfPizzas > 1
                && 'Die Teiglinge einzeln in eine oder mehrere Boxen legen'}
            {amountOfPizzas === 1 && 'Den Teigling in eine Box legen '}
            {' '}
            und
            wieder im Kühlschrank lagern.
        </p>
        <h3>Tag 3 - Heute gibt es Pizza</h3>
        <p>
            4 Stunden bevor die Pizza gebacken werden soll,
            {amountOfPizzas > 1 && ' können die Teiglinge'}
            {amountOfPizzas === 1 && ' kann der Teigling'}
            {' '}
            aus dem Kühlschrank
            genommen werden. Später, beim Entnehmen aus der Box gerne Mehl und
            eine Teigspachtel verwenden.
        </p>
        <p>
            Beim Ausrollen keine Teigrolle sondern die Hände verwenden! Die Luft
            soll im Teig, insbesondere im Rand, bleiben. Deswegen von der Mitte
            nach Aussen den Teig mit den Fingerspitzen platt drücken bzw.
            ausziehen, und dabei aufpassen, dass der Rand luftig bleibt.
        </p>
        <p>
            Pizza wie gewünscht belegen und in den heissen Ofen geben. Je mehr
            Hitze der Ofen schafft, desto besser. Ich nutze eine
            Pizzastahlplatte, die ich circa 15 Minuten vor der ersten Pizza im
            Ofen aufheize. Der Ofen hat eine Grillfunktion, weswegen ich die
            Pizza auf der heissen Stahlplatte ganz oben im Ofen backe. Dort ist
            sie nach circa 3 Minuten fertig.
        </p>

    </>;
}

export default PizzaTeig;
