import React from 'react';
import './App.css';
import PizzaTeig from "./components/PizzaTeig";

export default function App() {

    return (
        <div className='App'>
            <PizzaTeig />
        </div>
    );
}
